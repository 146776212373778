<template>
  <div class="flex flex-col">
    <div class="flex-1">
      <div class="text-white bg-dark">
        <div class="container flex flex-col pl-5 pr-4 mx-auto lg:pr-20 lg:flex-row">
          <div class="flex items-center lg:w-1/2 lg:mb-10 lg:mx-10">
            <div>
              <h1
                class="mt-6 mb-6 font-extrabold leading-tight lg:mb-10 text-mhead lg:text-head lg:mt-16"
              >We are experts— so you don’t have to be.</h1>
              <div>Far too often, hand doctors and therapists evaluate patients who are wearing the wrong splint for their hand pain. The sheer number of splints available today online and in your local pharmacy is overwhelming. Sometimes it can make your head hurt more than your hand...</div>
            </div>
          </div>

          <div class="mt-10 mb-16 lg:w-1/2 lg:mb-4">
            <img src="../assets/Questions.png" class="font-bold" />
          </div>
        </div>
      </div>
      <div class="text-black bg-white">
        <div
          class="container flex flex-col pl-5 pr-4 mx-auto mb-8 lg:mb-20 lg:pr-20 lg:flex-row-reverse"
        >
          <div class="mb-10 lg:w-1/2 lg:mx-10">
            <h1
              class="mt-6 mb-0 leading-tight lg:mb-10 text-mhead lg:text-head lg:mt-16"
            >That’s why we’re here to help.</h1>
            <div class="mb-2 lg:w-1/2 lg:hidden">
              <img src="../assets/ChecklistAnimation_NewSplint.gif" />
            </div>
            <div>We’re a group of healthcare professionals and consumers with an extensive history of understanding medical devices and helping people to find and use the right one. We find and evaluate the most common splints for the most common conditions. We compare and test them by studying the construction and the materials, the similarities and the differences. And we even spend time wearing each one ourselves to make sure they’re comfortable when worn for a long time.</div>
          </div>
          <div class="hidden mt-4 ml-10 -mb-10 lg:w-1/2 lg:block">
            <img src="../assets/ChecklistAnimation_NewSplint.gif" />
          </div>
        </div>
      </div>
      <div class="pb-3 text-black bg-shade">
        <div class="container flex flex-col pl-5 pr-4 mx-auto -mt-8 lg:pr-20 lg:flex-row-reverse">
          <div class="flex flex-row-reverse lg:w-1/8 lg:-mt-16">
            <img
              src="../assets/HelpingHand-01.svg"
              class="-mt-8 -mr-4 lg:mt-8 lg:mr-10"
              style="transform: scale(-1,1); max-width:150px;max-height:150px"
            />
            <p class="flex-1 mt-12 text-lg font-bold lg:hidden">A helping hand</p>
          </div>
          <div class="flex-1 mb-4 -mt-4 lg:mt-8 lg:pl-4">
            <p class="hidden text-lg font-bold lg:block">A helping hand</p>
            <p
              class="lg:pb-6"
            >Although some of us are healthcare professionals, of course there is no way to diagnose you without evaluating you. If you’re experiencing any pain or discomfort in your hand or wrist, you should see your doctor. But after years of helping our own patients find the best splints for their condition, we wanted to extend a hand (pun intended) to people everywhere who need some advice to find the right split for them without breaking the bank.</p>
          </div>
        </div>
      </div>

      <div class="pt-2 text-white bg-dark">
        <div class="container flex flex-col pl-5 pr-4 mx-auto lg:pr-20 lg:flex-row">
          <div class="mb-10 lg:w-1/2 lg:mx-10">
            <h1 class="mt-6 mb-6 leading-tight lg:mb-10 text-mhead lg:text-head lg:mt-16">
              Free advice.
              <br />Free of bias.
            </h1>
            <div class="flex justify-center mb-4 lg:w-1/2">
              <img src="../assets/armadillocomputer_1.gif" class="w-full h-full lg:hidden" />
            </div>
            <p>All of the reviews on this site are free from commercial bias. If you purchase an item through a link on our site, we may get a small commission from qualifying purchases—but we have no incentive to recommend any product over another except for our desire to help.</p>
            <p
              class="mt-4"
            >We firmly believe that there’s no reason to spend a fortune on a splint: most splints are only used for a short period of time and it doesn’t need to use the latest or most expensive materials in order to do its job. Even more important is to make sure that you are buying the splint that is most appropriate for your condition.</p>
          </div>
          <div class="flex mb-4 lg:w-1/2">
            <img src="../assets/armadillocomputer_1.gif" class="self-center hidden lg:block" />
          </div>
        </div>
      </div>
      <div class="mt-10 text-black bg-white ">
        <!-- <div class="container flex-col hidden pl-5 pr-4 mx-auto lg:pr-20 lg:flex-row">
          <div class="lg:w-1/2 lg:mx-10">
            <img src="../assets/Emily-01.svg" class="lg:mx-4" />
          </div>
          <div class="flex items-center lg:w-1/2 lg:mr-20">
            <div>
              <h1 class="mt-6 mb-2 leading-tight text-mhead lg:text-head lg:mt-0">Emily Altman</h1>
              <h3 class="text-lg font-bold">PT, DPT, CHT, OCS, CLT</h3>
              <p
                class="mt-4 leading-tight"
              >Emily is a physical therapist with an expertise in hand therapy. She has 25 years of clinical experience in caring for hand and wrist injuries. She works at the world-renowned Hospital for Special Surgery in New York where she has treated thousands of patients with custom splints and teaches therapy students locally, nationally and internationally.</p>
              <red-button
                href="https://www.hss.edu/rehab-staff_Altman-Emily.asp"
                class="mt-8"
              >Visit my practice</red-button>
            </div>
          </div>
        </div> -->
        <div
          class="container flex flex-col-reverse pl-5 pr-4 mx-auto mt-20 xl:mt-0 lg:pr-20 lg:pt-0 lg:flex-row"
        >
          <div class="flex items-center lg:w-1/2 lg:ml-20 lg:pl-8 lg:mr-10">
            <div>
              <h1 class="mt-6 mb-2 leading-tight text-mhead lg:text-head lg:mt-0">Aaron Daluiski</h1>
              <h3 class="text-lg font-bold">MD</h3>
              <p
                class="mt-4 leading-tight"
              >Aaron is an orthopedic hand surgeon specializing in adult and pediatric hand and upper extremity issues. For more than 20 years he’s treated hand, wrist, and elbow conditions at the Hospital for Special Surgery, examining hundreds of splint designs while helping thousands of patients.</p>
              <red-button
                href="https://www.hss.edu/physicians_daluiski-aaron.asp"
                class="mt-8"
              >Visit my practice</red-button>
            </div>
          </div>
          <div class="lg:w-1/2 lg:mr-20">
            <img src="../assets/Aaron-01.svg" class="mx-2" />
          </div>
        </div>
      </div>
    </div>
    <footer-md class="px-10 mt-20 text-gray-700 lg:px-0" />
  </div>
</template>


<script>
import Footer from "../components/Footer.vue";
export default {
  components: {
    "footer-md": Footer,
  },
};
</script>

<style>
</style>
